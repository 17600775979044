body {
  margin: 0;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka,
    "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", Meiryo, "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
  font-size: 16px;
  color: #40445b;
  scroll-behavior: smooth;
}

button {
  border-radius: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}