.border {
  border: 1px solid #2f313025;
  background-color: #f7f9fc;
}
.link {
  color: #0080cb;
}
.border-radius {
  border-radius: 3px;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: left;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  margin-left: 14px;
  padding-bottom: 42px;
}
