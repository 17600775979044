@import "vars.scss";

/* Width */
.m-wAuto {
    min-width: auto !important;
}

.wAuto {
    width: auto !important;
}

.w100 {
    width: 100% !important;
}

.w50 {
    width: 50%;
}

.wInput {
    width: 244px !important;
}

.wInputx2 {
    width: 503px !important;
}

// PX
.w20px {
    width: 20px;
}

.w17px {
    width: 17px;
}

.w18px {
    width: 18px;
}

.w20px {
    width: 20px;
}

.w60px {
    width: 60px !important;
}

.w80px {
    width: 80px !important;
}

.min-w60px {
    min-width: 60px !important;
}

.w155px {
    width: 155px;
}

.w200px {
    width: 200px;
}

.w300px {
    width: 300px;
}

.w810px {
    width: 810px;
}

.h500px {
    height: 500px;
}

.h30px {
    height: 30px;
}

.h39px {
    height: 39px;
}

// Font
.f-lighter {
    font-weight: lighter;
}

.fs10 {
    font-size: 10px !important;
}

.fs12 {
    font-size: 12px !important;
}

.fs13 {
    font-size: 13px !important;
}

.fs14 {
    font-size: 14px !important;
}

.fs16 {
    font-size: 16px !important;
}

.fs18 {
    font-size: 18px !important;
}

.fs26 {
    font-size: 26px !important;
}

// Cursor
.pointer {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.wait {
    cursor: wait;
}

/* Margin */

.m0 {
    padding: 0 !important
}

.mt0 {
    margin-top: 0px !important;
}

.mt1 {
    margin-top: 1px !important;
}

.mt2 {
    margin-top: 2px !important;
}

.mt3 {
    margin-top: 3px !important;
}

.mt4 {
    margin-top: 4px !important;
}

.mt5 {
    margin-top: 5px !important;
}

.mt6 {
    margin-top: 6px !important;
}

.mt7 {
    margin-top: 7px !important;
}

.mt8 {
    margin-top: 8px !important;
}

.mt9 {
    margin-top: 9px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt25 {
    margin-top: 25px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mt35 {
    margin-top: 35px !important;
}

.mt40 {
    margin-top: 40px !important;
}

.mt45 {
    margin-top: 45px !important;
}

.mt50 {
    margin-top: 50px !important;
}

.mt55 {
    margin-top: 55px !important;
}

.mt60 {
    margin-top: 60px !important;
}

.mt65 {
    margin-top: 65px !important;
}

.mt70 {
    margin-top: 70px !important;
}

.mt75 {
    margin-top: 75px !important;
}

.mt80 {
    margin-top: 80px !important;
}

.mt85 {
    margin-top: 85px !important;
}

.mt90 {
    margin-top: 90px !important;
}

.mt95 {
    margin-top: 95px !important;
}

.mt100 {
    margin-top: 100px !important;
}

.mb0 {
    margin-bottom: 0px !important;
}

.mb1 {
    margin-bottom: 1px !important;
}

.mb2 {
    margin-bottom: 2px !important;
}

.mb3 {
    margin-bottom: 3px !important;
}

.mb4 {
    margin-bottom: 4px !important;
}

.mb5 {
    margin-bottom: 5px !important;
}

.mb6 {
    margin-bottom: 6px !important;
}

.mb7 {
    margin-bottom: 7px !important;
}

.mb8 {
    margin-bottom: 8px !important;
}

.mb9 {
    margin-bottom: 9px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb25 {
    margin-bottom: 25px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb35 {
    margin-bottom: 35px !important;
}

.mb40 {
    margin-bottom: 40px !important;
}

.mb45 {
    margin-bottom: 45px !important;
}

.mb50 {
    margin-bottom: 50px !important;
}

.mb55 {
    margin-bottom: 55px !important;
}

.mb60 {
    margin-bottom: 60px !important;
}

.mb65 {
    margin-bottom: 65px !important;
}

.mb70 {
    margin-bottom: 70px !important;
}

.mb75 {
    margin-bottom: 75px !important;
}

.mb80 {
    margin-bottom: 80px !important;
}

.mb85 {
    margin-bottom: 85px !important;
}

.mb90 {
    margin-bottom: 90px !important;
}

.mb95 {
    margin-bottom: 95px !important;
}

.mb100 {
    margin-bottom: 100px !important;
}

.ml0 {
    margin-left: 0px !important;
}

.ml1 {
    margin-left: 1px !important;
}

.ml2 {
    margin-left: 2px !important;
}

.ml3 {
    margin-left: 3px !important;
}

.ml4 {
    margin-left: 4px !important;
}

.ml5 {
    margin-left: 5px !important;
}

.ml6 {
    margin-left: 6px !important;
}

.ml7 {
    margin-left: 7px !important;
}

.ml8 {
    margin-left: 8px !important;
}

.ml9 {
    margin-left: 9px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.ml15 {
    margin-left: 15px !important;
}

.ml20 {
    margin-left: 20px !important;
}

.ml25 {
    margin-left: 25px !important;
}

.ml30 {
    margin-left: 30px !important;
}

.ml35 {
    margin-left: 35px !important;
}

.ml40 {
    margin-left: 40px !important;
}

.ml45 {
    margin-left: 45px !important;
}

.ml50 {
    margin-left: 50px !important;
}

.ml55 {
    margin-left: 55px !important;
}

.ml60 {
    margin-left: 60px !important;
}

.ml65 {
    margin-left: 65px !important;
}

.ml70 {
    margin-left: 70px !important;
}

.ml75 {
    margin-left: 75px !important;
}

.ml80 {
    margin-left: 80px !important;
}

.ml85 {
    margin-left: 85px !important;
}

.ml90 {
    margin-left: 90px !important;
}

.ml95 {
    margin-left: 95px !important;
}

.ml100 {
    margin-left: 100px !important;
}

.mr0 {
    margin-right: 0px !important;
}

.mr1 {
    margin-right: 1px !important;
}

.mr2 {
    margin-right: 2px !important;
}

.mr3 {
    margin-right: 3px !important;
}

.mr4 {
    margin-right: 4px !important;
}

.mr5 {
    margin-right: 5px !important;
}

.mr-5 {
    margin-right: -5px !important;
}

.mr6 {
    margin-right: 6px !important;
}

.mr7 {
    margin-right: 7px !important;
}

.mr8 {
    margin-right: 8px !important;
}

.mr9 {
    margin-right: 9px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.mr12 {
    margin-right: 12px !important;
}

.mr15 {
    margin-right: 15px !important;
}

.mr20 {
    margin-right: 20px !important;
}

.mr25 {
    margin-right: 25px !important;
}

.mr30 {
    margin-right: 30px !important;
}

.mr35 {
    margin-right: 35px !important;
}

.mr40 {
    margin-right: 40px !important;
}

.mr45 {
    margin-right: 45px !important;
}

.mr50 {
    margin-right: 50px !important;
}

.mr55 {
    margin-right: 55px !important;
}

.mr60 {
    margin-right: 60px !important;
}

.mr65 {
    margin-right: 65px !important;
}

.mr70 {
    margin-right: 70px !important;
}

.mr75 {
    margin-right: 75px !important;
}

.mr80 {
    margin-right: 80px !important;
}

.mr85 {
    margin-right: 85px !important;
}

.mr90 {
    margin-right: 90px !important;
}

.mr95 {
    margin-right: 95px !important;
}

.mr100 {
    margin-right: 100px !important;
}



/* Padding */

.p0 {
    padding: 0 !important
}

.p16 {
    padding: 16px !important
}

.pt0 {
    padding-top: 0px !important;
}

.pt1 {
    padding-top: 1px !important;
}

.pt2 {
    padding-top: 2px !important;
}

.pt3 {
    padding-top: 3px !important;
}

.pt4 {
    padding-top: 4px !important;
}

.pt5 {
    padding-top: 5px !important;
}

.pt6 {
    padding-top: 6px !important;
}

.pt7 {
    padding-top: 7px !important;
}

.pt8 {
    padding-top: 8px !important;
}

.pt9 {
    padding-top: 9px !important;
}

.pt10 {
    padding-top: 10px !important;
}

.pt15 {
    padding-top: 15px !important;
}

.pt20 {
    padding-top: 20px !important;
}

.pt25 {
    padding-top: 25px !important;
}

.pt30 {
    padding-top: 30px !important;
}

.pt35 {
    padding-top: 35px !important;
}

.pt40 {
    padding-top: 40px !important;
}

.pt45 {
    padding-top: 45px !important;
}

.pt50 {
    padding-top: 50px !important;
}

.pt55 {
    padding-top: 55px !important;
}

.pt60 {
    padding-top: 60px !important;
}

.pt65 {
    padding-top: 65px !important;
}

.pt70 {
    padding-top: 70px !important;
}

.pt75 {
    padding-top: 75px !important;
}

.pt80 {
    padding-top: 80px !important;
}

.pt85 {
    padding-top: 85px !important;
}

.pt90 {
    padding-top: 90px !important;
}

.pt95 {
    padding-top: 95px !important;
}

.pt100 {
    padding-top: 100px !important;
}

.pb0 {
    padding-bottom: 0px !important;
}

.pb1 {
    padding-bottom: 1px !important;
}

.pb2 {
    padding-bottom: 2px !important;
}

.pb3 {
    padding-bottom: 3px !important;
}

.pb4 {
    padding-bottom: 4px !important;
}

.pb5 {
    padding-bottom: 5px !important;
}

.pb6 {
    padding-bottom: 6px !important;
}

.pb7 {
    padding-bottom: 7px !important;
}

.pb8 {
    padding-bottom: 8px !important;
}

.pb9 {
    padding-bottom: 9px !important;
}

.pb10 {
    padding-bottom: 10px !important;
}

.pb15 {
    padding-bottom: 15px !important;
}

.pb20 {
    padding-bottom: 20px !important;
}

.pb25 {
    padding-bottom: 25px !important;
}

.pb30 {
    padding-bottom: 30px !important;
}

.pb35 {
    padding-bottom: 35px !important;
}

.pb40 {
    padding-bottom: 40px !important;
}

.pb45 {
    padding-bottom: 45px !important;
}

.pb50 {
    padding-bottom: 50px !important;
}

.pb55 {
    padding-bottom: 55px !important;
}

.pb60 {
    padding-bottom: 60px !important;
}

.pb65 {
    padding-bottom: 65px !important;
}

.pb70 {
    padding-bottom: 70px !important;
}

.pb75 {
    padding-bottom: 75px !important;
}

.pb80 {
    padding-bottom: 80px !important;
}

.pb85 {
    padding-bottom: 85px !important;
}

.pb90 {
    padding-bottom: 90px !important;
}

.pb95 {
    padding-bottom: 95px !important;
}

.pb100 {
    padding-bottom: 100px !important;
}

.pb140 {
    padding-bottom: 140px !important;
}

.pl0 {
    padding-left: 0px !important;
}

.pl1 {
    padding-left: 1px !important;
}

.pl2 {
    padding-left: 2px !important;
}

.pl3 {
    padding-left: 3px !important;
}

.pl4 {
    padding-left: 4px !important;
}

.pl5 {
    padding-left: 5px !important;
}

.pl6 {
    padding-left: 6px !important;
}

.pl7 {
    padding-left: 7px !important;
}

.pl8 {
    padding-left: 8px !important;
}

.pl9 {
    padding-left: 9px !important;
}

.pl10 {
    padding-left: 10px !important;
}

.pl15 {
    padding-left: 15px !important;
}

.pl20 {
    padding-left: 20px !important;
}

.pl25 {
    padding-left: 25px !important;
}

.pl30 {
    padding-left: 30px !important;
}

.pl35 {
    padding-left: 35px !important;
}

.pl40 {
    padding-left: 40px !important;
}

.pl45 {
    padding-left: 45px !important;
}

.pl50 {
    padding-left: 50px !important;
}

.pl55 {
    padding-left: 55px !important;
}

.pl60 {
    padding-left: 60px !important;
}

.pl65 {
    padding-left: 65px !important;
}

.pl70 {
    padding-left: 70px !important;
}

.pl75 {
    padding-left: 75px !important;
}

.pl80 {
    padding-left: 80px !important;
}

.pl85 {
    padding-left: 85px !important;
}

.pl90 {
    padding-left: 90px !important;
}

.pl95 {
    padding-left: 95px !important;
}

.pl100 {
    padding-left: 100px !important;
}

.pr0 {
    padding-right: 0px !important;
}

.pr1 {
    padding-right: 1px !important;
}

.pr2 {
    padding-right: 2px !important;
}

.pr3 {
    padding-right: 3px !important;
}

.pr4 {
    padding-right: 4px !important;
}

.pr5 {
    padding-right: 5px !important;
}

.pr6 {
    padding-right: 6px !important;
}

.pr7 {
    padding-right: 7px !important;
}

.pr8 {
    padding-right: 8px !important;
}

.pr9 {
    padding-right: 9px !important;
}

.pr10 {
    padding-right: 10px !important;
}

.pr12 {
    padding-right: 12px !important;
}

.pr15 {
    padding-right: 15px !important;
}

.pr20 {
    padding-right: 20px !important;
}

.pr25 {
    padding-right: 25px !important;
}

.pr30 {
    padding-right: 30px !important;
}

.pr35 {
    padding-right: 35px !important;
}

.pr40 {
    padding-right: 40px !important;
}

.pr45 {
    padding-right: 45px !important;
}

.pr50 {
    padding-right: 50px !important;
}

.pr55 {
    padding-right: 55px !important;
}

.pr60 {
    padding-right: 60px !important;
}

.pr65 {
    padding-right: 65px !important;
}

.pr70 {
    padding-right: 70px !important;
}

.pr75 {
    padding-right: 75px !important;
}

.pr80 {
    padding-right: 80px !important;
}

.pr85 {
    padding-right: 85px !important;
}

.pr90 {
    padding-right: 90px !important;
}

.pr95 {
    padding-right: 95px !important;
}

.pr100 {
    padding-right: 100px !important;
}



/* Font */

.bold {
    font-weight: bold !important;
}


/*  */
.labelCount {
    border: 2px solid #c1c1c1;
    margin-left: 15px;
    padding-left: 15px;
    height: 15px;
    line-height: 15px;
    border-top: none;
    border-right: none;
    border-bottom: none;
}

/* user Select */
.nonSelect,
.user-select-none {
    user-select: none;
    cursor: default !important;
}

.ant-tabs-tab.ant-tabs-tab-active {

    select,
    .textOverflow {
        font-weight: bold;
        font-size: 101%;
    }
}

.textOverflow {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0px;
}

// Link
.link-white {
    color: white;
}

.link-white:hover {
    color: white;
}

/* Color */
.white {
    color: white;
}

/* Color */
.blue {
    color: blue;
}

.red {
    color: $i--error;
}

.bg-red:not([aria-disabled="true"]) {
    background-color: $i--error;
    color: white;

    &:hover {
        background-color: $i--error;
        color: white;
    }
}

/* Border */
.border-red:not(.ant-select) {
    border-radius: $borderRadius !important;
    border: 1px solid #dc3545 !important;

    &:hover {
        border: 1px solid #dc3545 !important;
    }

    &:focus {
        border-color: #dc3545 !important;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, .25);
    }
}

.border-bottom-black {
    border-bottom: 1px solid rgb(163, 159, 159);
}

.border-red.ant-picker-focused {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, .25);
}

.ant-select {
    border-radius: $borderRadius;
}

.border-red.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #dc3545 !important;
}

/* Border -- Select */
.ant-select-focused.border-red {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, .25);

    .ant-select-selector {
        border-color: transparent !important;
        box-shadow: none !important;
    }
}

// Span
.oss-label,
.white-space {
    white-space: pre-wrap;
    word-break: break-all;
}

.nowrap {
    white-space: nowrap;
    flex-wrap: nowrap;
}

.textAreaView {
    width: 240px;
    margin-bottom: 5px;
    font-size: 16px !important;
    padding: 0 16px;
}

.inputView {
    width: 240px;
    margin-bottom: 5px;
    height: 40px !important;
    font-size: 16px !important;
    text-align: center;
    font-weight: 600 !important;
    padding: 0 16px;

    &--red {
        border: 1px solid #ffa6a6 !important;
        background-color: #ffeaea !important;
        border-color: #dc3545;

        &:focus {
            box-shadow: 0 0 0 2px #dc354540;
        }
    }

    .ant-input-clear-icon {
        font-size: 18px;
    }
}

.divText {
    border: 1px solid $color-border;
    border-radius: 4px;
    line-height: 30px;
    height: 35px;
    padding: 4px 11px;
    overflow: hidden;
}

.overflow-auto {
    overflow: auto !important;
}

.bg-disable {
    background: #fafafa;
}

.text-cut {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.horizontal-Item {
    display: flex;
}

.display-none {
    display: none;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.table-Role td {
    border: 1px solid;
    text-align: left;
    padding: 5px;
}

.table-Role th {
    border: 1px solid;
    background-color: rgba(124, 174, 240, 0.735);
    padding: 5px;
}

.table-Role {
    width: 100%;
    border-collapse: collapse;
}

.margin-right15 {
    margin-right: 15px;
}