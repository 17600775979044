@import "vars.scss";
.ant-layout-sider {
  background: #283042;
  .ant-layout-sider-trigger {
    transition: 0.5s;
  }
}
.ant-layout-sider-trigger {
  background: #1d232f;
}
.site-layout {
  // height: 100%;
  transition: 0.5s;
  &-background {
    background: #fff;
    min-height: auto !important;
    border-radius: 4px;
    box-shadow: 0 0 0.875rem 0 #2930420d;
    margin-top: 0 !important;
  }
}

.align-center {
  display: flex;
  align-items: center !important;
}
.content-center {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}
.button-center {
  display: inline-flex;
  align-items: center !important;
}

.btnDeleteAll:not([aria-disabled="true"]) {
  color: #fff;
  background-color: #919191;
  border-color: #838383;
  &:hover {
    color: #fff;
    background-color: #c82333 !important;
    border-color: #bd2130 !important;
  }
}
.des {
  color: #333;
  font-size: 14px !important;
  min-height: calc(100vh - 58px);
  &--white {
    // height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #fff;
    .text-right {
      text-align: right;
    }
  }
  table {
    a {
      color: $main_color;
    }
  }

  thead > tr:last-child th {
    margin: 0px;
    padding: 8px;
  }

  &-content {
    max-width: 100%;
    margin-top: 70px;
    height: 100%;
    &__mobile {
      background-color: $bg_mobile !important;

      .tabsResponsive {
        margin: auto -16px;
      }
    }
  }
  a {
    color: $main_color;
  }

  .ant-btn {
    min-width: 103px;
  }

  .ant-tabs-nav .ant-tabs-tab {
    border-radius: $borderRadius $borderRadius 0 0;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: $bg_tab_other;
  }

  // Tab
  .tagMachine {
    font-size: 14px;
    padding: 4px 16px;
    border-radius: 4px;
  }

  .ant-tag-close-icon:hover,
  .ant-tag:hover .ant-tag-close-icon {
    color: #e13c3c;
  }

  // Table
  table {
    a:hover {
      text-decoration: underline;
    }

    a:visited {
      color: #663399;
    }
  }
  // Form
  .formDes {
    .ant-form-item {
      margin-bottom: 12px;
    }
    .ant-form-item-label {
      font-weight: normal !important;
    }
  }

  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 2px;
    font-weight: bold;
  }
  .ant-table {
    .ant-btn:not(.btnLink):not(.btnLink-active) {
      min-width: auto;
      max-height: 30px !important;
      font-size: 14px !important;
    }

    // Role screen
    .btnAddRole {
      font-weight: 700 !important;
      color: white;
      height: 26px !important;
      padding: 0 15px;
    }
  }

  .listVer {
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: #333;
    background: #dfe0e04a;
    border-radius: 4px;
    border: none;
  }

  /* Button, Input */
  // Button Link

  .btnAddRecord {
    padding: 0 !important;
    // margin: 0!important;
    align-items: center;
    display: flex;
    border: 0px !important;
    min-width: auto !important;
    font-weight: bold;
    font-size: 14px;
    color: $main_color;

    svg {
      margin-right: 5px;
    }
  }

  .btnLink {
    display: flex;
    align-items: center;
    height: auto !important;
    width: auto;
    margin: 0;
    padding: 0;
    min-width: auto;
    color: $main_color;

    // Default
    path {
      color: $main_color;
      fill: $info-more;
    }

    // Active
    &-active:hover {
      path {
        color: $main_color;
        fill: $main_color;
      }
    }

    &-error {
      &:focus {
        border: 1px solid #dc3545;
        border-color: #dc3545;
        padding: 0 2px;
        box-shadow: 0 0 0 0.2rem #dc354540;
      }
      path {
        fill: $text-danger;
      }
    }
  }

  .btnEdit:hover,
  .btnEdit:active {
    border-color: $second_color;
    color: $second_color;
  }
  .btnView:hover,
  .btnView:active {
    border-color: #45b8db;
    color: #3faaca;
  }
  .btnDelete:hover,
  .btnDelete:active {
    border-color: #e3473e;
    color: #d14139;
  }
  .btnClone:hover,
  .btnClone:active {
    border-color: #3d81bf;
    color: #3d81bf;
  }

  .btnM,
  .inputM {
    height: 32px !important;
    font-size: 17px !important;
    min-width: auto;
  }

  .btnS {
    height: 21px !important;
    font-size: 13px !important;
    padding: 0px 8px;
    min-width: auto;
  }
}

.input-clear {
  padding-right: 30px;

  &-icon {
    position: absolute;
    top: 5px;
    right: 9px;

    & > span {
      color: #00000040;
    }

    &:hover > span {
      color: #00000073;
      cursor: pointer;
    }
    &:active > span {
      color: #000000d9;
    }
  }
}

.formStyle label {
  font-size: 14px;
}

.ant-popover {
  a:hover {
    text-decoration: underline;
  }
}

// Common
.ant-modal-body {
  .tableHtml {
    .ant-col {
      border: 1px solid $color-border;
      border-bottom: 0px;
      border-right: 0px;
    }

    .ant-col:last-child {
      border-right: 1px solid $color-border;
    }

    .ant-row:last-child {
      border-bottom: 1px solid $color-border;
    }
  }
}

// Modal
.modal-confirm {
  .ant-modal-body {
    text-align: center;
  }
}

.ant-modal-wrap {
  z-index: 10002; // update for mobile
}
.ant-select-dropdown {
  z-index: 10003; // fixbug
}

.modal-msg {
  a {
    color: $main_color;
  }
  // .ant-modal-title span:first-child {
  //   margin-right: 20px;
  //   font-size: 22px;
  // }
  .ant-modal-body {
    font-size: 14px;
  }

  .ant-btn {
    min-width: 6em;
    // padding: 5px 20px;

    // border-radius: 4px !important;
    // Size
    // height: 40px !important;
    // font-size: 18px !important;
    font-weight: 500 !important;
  }

  .btnM {
    height: 35px !important;
    font-size: 17px !important;
    min-width: auto;
  }

  .btnS {
    height: 21px !important;
    font-size: 13px !important;
    padding: 0px 8px;
    min-width: auto;
  }
}

.modal-screen {
  .ant-modal-body {
    height: calc(100vh - 213px);
    overflow: auto;
  }
}

.box-search-machine {
  width: 97% !important;
  max-width: 1200px;
  .ant-modal-body {
    max-height: calc(100vh - 180px);
    height: 465px;
    overflow-y: auto;

    .ant-col .ant-form-item-label {
      padding: 0 0 0px;
    }

    .box-select-row {
      height: 405px;
    }
  }
}

.modal-msg:not(.modal-confirm),
.ant-modal {
  padding-bottom: 0;

  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ant-modal-title {
    white-space: break-spaces;
    font-size: 18px;
    font-weight: bold; ////// Bold
    line-height: inherit !important;
  }
}

.modal-msg:not(.modal-confirm):not(.modal-padding) {
  .ant-modal-body {
    padding: 0;
  }
}

.modal {
  // Error
  &-error {
    .ant-modal-header {
      color: #b94a48;
      background-color: #f2dede;
      border-color: #eed3d7;

      .ant-modal-title {
        color: #b94a48;
      }
    }
  }

  // warning
  &-warning {
    .ant-modal-header {
      color: #c09853;
      background-color: #fcf8e3;
      border-color: #fbeed5;

      .ant-modal-title {
        color: #c09853;
      }
    }
  }

  // warning
  &-warning2 {
    width: 456px !important;
    .ant-modal-title {
      & > span {
        float: left;
        font-size: 70px;
        color: #faac14;
      }
      .modal-custom-content {
        font-size: 16px;
        padding-left: 83px;
      }
    }
    .ant-modal-header {
      padding: 32px 32px 24px;
      // color: #c09853;
      // background-color: #fcf8e3;
      // border-color: #fbeed5;

      // .ant-modal-title {
      //   color: #c09853;
      // }
    }
  }

  // success
  &-success {
    .ant-modal-header {
      background-color: #dff0d8;
      color: #21902a;
      border-color: #dff0d8;

      .ant-modal-title {
        color: #21902a;
      }
    }
  }
}

// Table view

.ant-tabs-nav-add,
.ant-tabs-tab {
  font-size: 12px !important;
}
.ant-tabs-nav-add {
  @include desktop {
    height: 35px;
  }
  font-size: 12px !important;
  border-bottom-color: $bg_tab_other !important;
}

.tbl-view {
  .ant-tabs-tab {
    border: 1px solid $color-border;
    margin-right: 2px;
    padding: 5px 20px;

    &.ant-tabs-tab-active {
      border-bottom-color: #ffffff !important;

      .ant-tabs-tab-btn {
        // color: #169BD5 !important;
        font-weight: bold;
      }
    }

    // .ant-tabs-tab-btn {
    //   // font-size: 17px;
    //   // font-weight: bold;
    // }
  }

  .ant-tabs-content-holder {
    background-color: white;
    padding: 0 0 0;
  }

  .ant-tabs-left {
    .ant-tabs-tabpane {
      padding-left: 0 !important;
    }
  }
}

.tbl-original_del {
  .ant-table-row-expand-icon {
    border: 1px solid #6b6b6b;
  }

  .ant-table-thead > tr > th {
    background-color: #6b6b6b;
    color: white;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr:nth-child(even) {
    background: #f5f3f4;
  }

  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: 1px solid #cdcdcd;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right: 1px solid #cdcdcd;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #cdcdcd;
  }
}

/* Grid */
.tblInventory {
  .ant-table-thead > tr > th {
    height: 45px;
    white-space: pre;
  }
}

.tblRole {
  .ant-select-single {
    font-weight: bold;
  }
}

/* Search */
.gridSearch .ant-table-thead > tr > th {
  height: 45px;
  color: #343a40;
  border-color: #e0e4e7;
}
.gridDashboard {
  .ant-table-cell-scrollbar {
    display: none;
  }
}
//
.row-highlight {
  background: #ffeaea !important;}
// Copy
.activeButton:active {
  color: $main_color;
  span {
    color: $main_color;
  }
}
.underline {
  color: #1394df;
  cursor: pointer;
}
// Tab
.btnNextPrev {
  display: none;
  margin: 0 3px !important;

  span {
    padding: 0 5px;
  }

  .btnPrev,
  .btnNext {
    padding: 5px 6px;
    background: $hover_bg;
    border-radius: $borderRadius;
    margin: 2px;

    path {
      fill: $main_color;
    }

    &:disabled {
      path {
        fill: $disable_check;
      }
    }

    &:hover {
      background: $hover_bg;
    }
  }
}
.ant-tabs-top
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  div {
  height: 20px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-bottom-color: $color-border !important;
}
.tbl-createCL.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-bottom-color: $color-border !important;
}
.ant-tabs-tab-active {
  background: $bg_tab_active !important;
}
.tab-attach.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-bottom-color: #d9d9d9 !important;
}
.tab-attach.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom-color: white !important;
}
.tab-attach.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom-color: white !important;
}

// For checkbox all
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  opacity: 0;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: transparent;
  // border-color: transparent;
}

.ant-table-selection {
  display: block;
  margin: auto;
}

// Paging
.ant-pagination-options {
  display: none;
}

// .ant-checkbox-checked .ant-checkbox-inner {
//   background-color: $main_color !important;
//   // border-color: #1890ff;
// }
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: inherit;
}
// .ant-checkbox-input {
//     position: absolute;
//     top: 10px;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     z-index: 1;
//     width: 100%;
//     height: 100%;
//     cursor: pointer;
//     opacity: 1;
// }

.tbl-original tbody > tr.ant-table-expanded-row > td:before {
  content: "";
  position: absolute;
  width: 52px;
  height: 22px;
  background-color: transparent;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  border-right: none;
  left: 18px;
  top: 0px;
}

.ant-table.ant-table-bordered.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr.ant-table-expanded-row
  > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr.ant-table-placeholder
  > td {
  border-right: 0;
  background: #e4e4e485;
}

.sub-table {
  max-width: 40% !important;
  min-width: 550px !important;
  border-collapse: collapse !important;
  margin-bottom: 15px;
  margin-top: 0px;
  margin-left: 60px;
  border: 1px solid grey;
}

.sub-table > tbody > tr {
  & > td,
  & > th {
    text-align: center;
  }

  & > th {
    padding: 7px;
  }

  & > td {
    padding: 2px;
    border: 1px solid gray;
  }
}

.sub-table > thead > tr > th {
  text-align: center;
  padding: 7px;
}

.sub-table > thead > tr > th:nth-child(1) {
  width: 30%;
}
.sub-table > thead > tr > th:nth-child(2) {
  width: 30%;
}
.sub-table > thead > tr > th:nth-child(3),
.sub-table > thead > tr > th:nth-child(4),
.sub-table > thead > tr > th:nth-child(5) {
  width: 15%;
}

// .sub-table > thead > tr > th:nth-child(2),
// .sub-table > thead > tr > th:nth-child(3) {
//   width: 40%;
//   border-left: 1px solid #fff;
// }
// .sub-table > thead > tr > th:nth-child(4) {
//   width: 20%;
//   border-left: 1px solid #fff;
// }

// .sub-table > tbody > tr > td:nth-child(1) {
//   width: 40%;
// }

// .sub-table > tbody > tr > td:nth-child(2),
// .sub-table > tbody > tr > td:nth-child(3) {
//   width: 40%;
// }
// .sub-table > tbody > tr > td:nth-child(4) {
//   width: 20%;
// }

.sub-table > thead > tr {
  background-color: #6b6b6b;
  color: #fff;
}

//
.grid-ver {
  font-size: 14px;
  font-weight: 500;
}

.menu-ver {
  color: #9a9a9a;
}

.title-ver {
  font-size: 18px;
  margin-left: 5px;
  font-weight: 500;
  color: #333;
}

.wrap-unsetw-btn .ant-btn {
  min-width: unset;
}

.my-upload {
  .ant-upload.ant-upload-select-picture-card {
    width: 250px;
    height: 250px;
  }

  .ant-upload-list-picture-card-container {
    width: 250px;
    height: 250px;
  }
}