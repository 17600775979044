@import "./vars.scss";

$bg--warning: #f9cc9d;
$bg--warning2: #ffa326;
$bg--login: #1f212e;
$bg--error: #e13c3c;
$bg--info: $main_color;
$bg--info_hover: $main_color-hover;
$bg--cancel: #d6d6d6;
$bg--back: #f9f9f9;

$bg--success: #61bc48;
$bg--confirm: #ffbf6a;

$bg--disabled: #f5f5f5;
$color--disabled: rgba(0, 0, 0, 0.25);
$border--disabled: #d9d9d9;

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: $main_color;
  border-color: $main_color;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #0090e4;
  border-color: $main_color;
}

.buttonPC {
  border: transparent;
  color: white;
  & > .anticon {
    font-size: 14px;
  }
  &.button {
    height: 40px;
    width: 100%;
    border: 0;
    border-radius: 5px;
    color: whitesmoke;
    &--outline {
      border: 1px solid $bg--info;
      color: $bg--info;
      background-color: #fff;

      &:hover,
      &:focus {
        color: $main_color-hover !important;
        border: 1px solid $main_color-hover !important;
      }
      &[disabled] {
        border: 1px solid $border--disabled;
        color: $color--disabled;
        background-color: $bg--disabled;
        &:hover {
          border: 1px solid $border--disabled !important;
          color: $color--disabled  !important;
          background-color: $bg--disabled !important;
        }
      }
    }
    &--big {
      height: 60px;
      font-size: 20px;
      font-weight: 500;
    }
    &--cancel {
      background-color: $bg--cancel;
      border: 1px solid #bfbfbf;
      color: #717171;
      &.ant-btn:focus,
      &.ant-btn:hover {
        background-color: #ececec !important;
        color: #717171;
        border: 1px solid #bfbfbf;
      }
    }
    &--back {
      background-color: $bg--back;
      border: 1px solid #e4e4e4;
      color: #717171;
      &.ant-btn:focus,
      &.ant-btn:hover {
        background-color: white !important;
        color: #717171;
        border: 1px solid #e4e4e4;
      }
    }
    &--warning {
      background-color: $bg--warning;
      &.ant-btn:focus {
        background-color: $bg--warning !important;
        color: white !important;
      }
      &:hover,
      :focus {
        background-color: $bg--warning !important;
        color: white !important;
      }
    }
    &--warning2 {
      background-color: $bg--warning2;
      &.ant-btn:focus {
        background-color: $bg--warning2 !important;
        color: white !important;
      }
      &:hover,
      :focus {
        background-color: $bg--warning2 !important;
        color: white !important;
      }
    }
    &--confirm {
      background-color: $bg--confirm;
      &.ant-btn:focus {
        background-color: $bg--confirm !important;
        color: white !important;
      }
      &:hover,
      :focus {
        background-color: $bg--confirm !important;
        color: white !important;
      }
    }
    &--success {
      background-color: $bg--success;
      &.ant-btn:focus {
        background-color: $bg--success !important;
        color: white !important;
      }
      &:hover,
      :focus {
        background-color: $bg--success !important;
        color: white !important;
      }
    }
    &--login {
      background-color: $bg--login;
      &.ant-btn:focus {
        background-color: $bg--login !important;
        color: white !important;
      }
      &:hover,
      :focus {
        background-color: $bg--login !important;
        color: white !important;
      }
    }

    &--error {
      background-color: $bg--error;
      &.ant-btn:focus {
        background-color: $bg--error !important;
        color: white !important;
      }
      &:hover,
      :focus {
        background-color: $bg--error !important;
        color: white !important;
      }

      &--outline {
        border: 1px solid $bg--error !important;
        color: $bg--error !important;
        background-color: white !important;
      }
    }

    &--info {
      background-color: $bg--info;
      &.ant-btn:focus {
        background-color: $main_color !important;
        color: white !important;
        box-shadow: 0 0 0px 2px #00a0fe4d;
      }
      &:hover,
      :focus {
        background-color: $bg--info_hover !important;
        color: white !important;
      }
      &:disabled {
        background: $bg--info;
        background-color: $bg--info !important;
        color: white !important;
        border-color: transparent;
        border-width: 1px;
        border-style: solid;
        opacity: 0.3;
      }
    }
  }
}
